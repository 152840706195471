import IconHeart from '@betterplace/assets/images/icons/heart.svg'
import React, { useMemo } from 'react'
import classNames from 'classnames'
import styles from './donation.module.css'
import { DonationProps } from './types'
import { Donation as DonationType } from 'ppp/donations_list/types'
import { I18nHtml, PlainText, findApiV4Link } from 'shared'
import { formatCents } from '@betterplace/utils'

import { LinksContainer } from 'shared/helpers/api_v4_helper/types'
import { type Locale, de, enUS } from 'date-fns/locale'
import { formatDistance, parseISO } from 'date-fns'
const locales: Record<string, Locale> = { en: enUS, de }
export const Donation = ({ donation, as: Tag = 'div' }: DonationProps) => {
  const sponsored = !!donation?.backed_by_fundraising_event?.sponsoring_name

  const imageSrc = useMemo(
    () =>
      sponsored
        ? findApiV4Link({
            ofObject: donation.backed_by_fundraising_event as LinksContainer<string>,
            withRel: 'sponsoring_logo',
          })
        : donation.author?.picture &&
          !donation.author.picture.fallback &&
          findApiV4Link({ ofObject: donation.author.picture as LinksContainer<string>, withRel: 'fill_100x100' }),
    [donation, sponsored]
  )

  const classes = useMemo(
    () =>
      classNames(styles.container, {
        [styles.sponsored]: sponsored,
        [styles.hasImage]: !!imageSrc,
      }),
    [imageSrc, sponsored]
  )

  return (
    <Tag className={classes} data-testid="donation">
      <div className={styles.donationItem}>
        <SenderVisual donation={donation} imageSrc={imageSrc} />
        <SenderInfo donation={donation} hasImageSrc={!!imageSrc} sponsored={sponsored} />
      </div>
      <AdditionalInfos donation={donation} />
    </Tag>
  )
}

const SenderVisual = ({ donation, imageSrc }: { donation: DonationType; imageSrc: string | false | undefined }) => {
  return (
    <div className={styles.senderVisualContainer}>
      {imageSrc ? (
        <img src={imageSrc} alt="" />
      ) : (
        <>
          {donation.donated_amount_in_cents ? (
            <FluidText
              content={formatCents(
                i18n.locale,
                donation.donated_amount_in_cents + (donation.matched_amount_in_cents ?? 0)
              )}
            />
          ) : (
            <img className={styles.visualPlaceholderIcon} src={IconHeart} alt="" />
          )}
        </>
      )}
    </div>
  )
}

const FluidText = ({ content }: { content: string }) => {
  const size = (6 / content.length).toString() + 'rem'

  return <strong style={{ fontSize: `min(${size}, 1rem)` }}>{content}</strong>
}

const SenderInfo = ({
  donation,
  sponsored,
  hasImageSrc,
}: {
  donation: DonationType
  sponsored: boolean
  hasImageSrc: boolean
}) => {
  const timeAgo = useMemo(
    () =>
      donation.confirmed_at
        ? formatDistance(parseISO(donation.confirmed_at), Date.now(), {
            addSuffix: true,
            locale: locales[i18n.locale],
          })
        : '',
    [donation.confirmed_at]
  )
  const senderName = sponsored
    ? (donation?.backed_by_fundraising_event?.sponsoring_name ?? '')
    : donation.author
      ? (donation.author.name ?? '')
      : i18n.t('projects.show.donation.anonymous_display_name')
  return (
    <div className={styles.senderInfoContainer}>
      <div>
        <PlainText className={styles.senderName}>{senderName}</PlainText>
        <span>
          <span>&nbsp;</span>
          <span>
            {donation.backed_by_fundraising_event ? (
              <I18nHtml
                i18nKey="projects.show.donation.author_addon_forwarded_html"
                options={{
                  donor_count: donation.backed_by_fundraising_event.donor_count,
                  donation_amount: donation.donated_amount_in_cents
                    ? formatCents(i18n.locale, donation.donated_amount_in_cents)
                    : '',
                }}
              />
            ) : (
              i18n.t('projects.show.donation.author_addon', {
                donation_amount:
                  hasImageSrc && !!donation.donated_amount_in_cents
                    ? formatCents(i18n.locale, donation.donated_amount_in_cents)
                    : '',
              })
            )}
          </span>
        </span>
      </div>
      <div className={styles.senderTime}>{timeAgo}</div>
    </div>
  )
}

const AdditionalInfos = ({ donation }: { donation: DonationType }) => {
  return (
    <div className={styles.additionalInfosContainer}>
      {/* comes as "sanitized" HTML from APIv4 */}
      {donation.backed_by_fundraising_event && (
        <I18nHtml
          i18nKey="projects.show.donation.fundraising_event_message_html"
          as="div"
          options={{
            fundraising_event_url: donation.backed_by_fundraising_event.url,
            fundraising_event_title: donation.backed_by_fundraising_event.title,
          }}
          className={styles.additionalInfo}
        />
      )}
      {donation.client_name && (
        <I18nHtml
          className={styles.additionalInfo}
          as="div"
          i18nKey="projects.show.donation.client_prefix_html"
          options={{ client_name: donation.client_name }}
        />
      )}
      {/* comes as "sanitized" HTML from APIv4 */}
      {donation.message && (
        <PlainText as="div" className={styles.additionalInfo}>
          {donation.message}
        </PlainText>
      )}
    </div>
  )
}
