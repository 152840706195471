import React, { useCallback, useEffect, useMemo } from 'react'

// This is going to be imported in application.css once we use the new ITCSS structure in other places, too.
import 'stylesheets/it.scss'

import {
  AdminBox,
  CtaBar,
  CtaButtonGroup,
  Gallery,
  GtmHelper,
  I18nHtml,
  LogoSpinner,
  SharingGridButton,
  TaxNotice,
  report,
  themeColor,
  useJsonApi,
} from 'shared'

import styles from './ppp.module.css'

import classNames from 'classnames'
import { About } from './about'
import { ClientBannerList } from './client_banner_list'
import { CreateFundraisingEventCta } from './create_fundraising_event_cta'
import { Description } from './description'
import { DonateButton } from './donate_button'
import { DonationsList } from './donations_list'
import { Engagement } from './engagement'
import { Header } from './header'
import { LogoTextBanner, MatchingEventNotice } from './matching_event_notice'
import { MatchingEvent } from './matching_event_notice/types'
import { NeedList } from './need_list'
import { NewsTeaserList } from './news_teaser_list'
import { OrganisationProjectSummary } from './organisation_project_summary'
import { PPPProject, PPPProps, SharedDonateButtonProps } from './types'
import { Progress } from './progress'
import { ProjectInfo } from './project_info'
import { PublicFaceInfo } from './public_face_info'
import { Section } from './section'
import { SharingContainer } from './sharing_container'
import { SubscribeCta } from './subscribe_cta'
import { VideoPlayer } from 'modules'
import { isArray } from '@betterplace/utils'

export const ProjectPageLoader = ({ url }: { url: string }) => {
  const { data, loading } = useJsonApi<PPPProps>(url)

  if (loading)
    return (
      <div className={styles.loadingContainer}>
        <LogoSpinner color={themeColor('green-500')} />
      </div>
    )

  if (data) return <ProjectPage {...data} />

  //Todo: Discuss what error message we want to show here
  return null
}

const useSharedDonateButtonProps = (project: PPPProject, donateUrl: string): SharedDonateButtonProps => {
  const props = useMemo(
    () => ({
      status: project.status,
      donateUrl: donateUrl,
      progressPercentage: project.progressPercentage,
      projectId: project.id,
      projectTitle: project.title,
      carrierId: project.carrier?.id,
      carrierName: project.carrier?.name || '?',
      carrierBlocked: project.carrier?.blocked,
    }),
    [project.carrier, project.id, project.progressPercentage, project.status, project.title, donateUrl]
  )
  return props
}

const useGtmProjectImpressions = (project: PPPProject) => {
  useEffect(() => {
    GtmHelper.pushProductDetailImpression(project)
  }, [project])
}

const ProjectPage = (props: PPPProps) => {
  useGtmProjectImpressions(props.project)

  const { data: matchingEvents } = useJsonApi<Array<MatchingEvent>>(props.links.matchingEventsUrl)

  if (matchingEvents && !isArray(matchingEvents)) {
    report('matchingEventsUrl should return an array instead received', {
      matchingEvents: JSON.stringify(matchingEvents),
      link: props.links.matchingEventsUrl,
    })
  }
  const isNotDonatable =
    props.project.status !== 'activated' || props.project.progressPercentage >= 100 || props.project.carrier?.blocked

  const sharedDonateButtonProps = useSharedDonateButtonProps(props.project, props.links.donateUrl)

  const onNewsLinkClicked: React.MouseEventHandler<HTMLAnchorElement> = useCallback((event) => {
    event.preventDefault()
    const newsListElement = document.querySelector('#ppp-news-list')
    if (newsListElement instanceof HTMLElement) {
      newsListElement.scrollIntoView({ behavior: 'smooth' })
      setTimeout(() => newsListElement.focus(), 500)
    }
  }, [])

  return (
    <>
      <CtaBar sticky hasStatusMessage={isNotDonatable}>
        <CtaButtonGroup
          shareUrl={props.links.projectShareUrl}
          hasStatusMessage={isNotDonatable}
          {...sharedDonateButtonProps}
          utmMedium="ppp_sticky"
        />
      </CtaBar>

      {props.project.managedByCurrentUser ? (
        <aside className={classNames(styles.adminBox, 'container')}>
          <AdminBox variant="dark">
            <a href={props.links.manageProjectDashboardUrl} className="btn btn-large btn-primary">
              {i18n.t('projects.show.manage.project')}
            </a>
          </AdminBox>
        </aside>
      ) : null}

      <Header
        projectTitle={props.project.title}
        projectStatus={props.project.status}
        profilePicture={props.project.profilePicture}
        gallery={
          <Gallery
            profilePicture={props.project.profilePicture}
            apiUrl={props.links.picturesApiUrl}
            additionalPicturesCount={props.project.picturesCount}
          />
        }
        videoEmbedUrl={props.links.videoEmbedUrl}
      />

      <Section>
        <OrganisationProjectSummary
          carrierProfilePictureUrl={props.project.carrier?.profilePicture.url}
          carrierUrl={props.links.carrierUrl}
          carrierName={props.project.carrier?.name || '?'}
          projectCity={props.project.city}
          projectCountry={props.project.country}
          shortDescription={props.project.summary}
        />
      </Section>

      <Section>
        {isArray(matchingEvents) &&
          matchingEvents.map((matchingEvent, key) => (
            <MatchingEventNotice
              matchingEvent={matchingEvent}
              donateButton={
                <DonateButton buttonLocation="PPP MatchingEvent" size="large" {...sharedDonateButtonProps} />
              }
              key={key}
            />
          ))}
        {(props.project.activeMatchingFund.logoUrl || props.project.activeMatchingFund.title) && (
          <LogoTextBanner
            logoUrl={props.project.activeMatchingFund.logoUrl}
            logoAlt={props.project.activeMatchingFund.title}
          >
            <I18nHtml as="span" i18nKey="projects.show.stats.matching_fund_notice" />
          </LogoTextBanner>
        )}
      </Section>

      <Section background="gentle" backgroundMobileOnly={true}>
        {/* TODO: extract this out as a layout component */}
        <div className="flex flex-col-reverse desktop:flex-row gap-6">
          <div className="desktop:w-1/2">
            <DonationsList
              apiUrl={props.links.opinionsApiUrl}
              donationsCount={props.project.donationsCount}
              wwBranchName={props.project.wwBranchName}
            />
          </div>
          <div className="desktop:w-1/2" data-testid="ppp-progress-donate">
            <Progress
              progressPercentage={props.project.progressPercentage}
              donatedAmountInCents={props.project.donatedAmountInCents}
              targetAmountInCents={props.project.targetAmountInCents}
              donationsCount={props.project.donationsCount}
              link={
                props.project.newsCount > 0 && (
                  <a href="#ppp-news-list" onClick={onNewsLinkClicked}>
                    {i18n.t('projects.show.stats.updates')}
                  </a>
                )
              }
            />
            {props.project.status === 'activated' && props.project.progressPercentage < 100 && (
              <div className={styles.taxNotice}>
                <TaxNotice highlightStart={true} />
              </div>
            )}
            <div className={styles.buttonGroup}>
              <DonateButton buttonLocation="PPP Main" size="large" block {...sharedDonateButtonProps} />
              <SharingGridButton
                shareUrl={props.links.projectShareUrl}
                platforms={['email', 'facebook', 'twitter', 'link', 'whatsapp', 'messenger']}
                modalHeader={i18n.t('projects.show.stats.share_modal_headline')}
                buttonLabel={i18n.t('projects.show.stats.share_button_label')}
                block
                size={'large'}
                utmMedium="ppp_stats"
              />
            </div>
          </div>
        </div>
      </Section>

      <Section spacing="large">
        <ProjectInfo
          media={
            props.links.videoEmbedUrl && (
              <div className={styles.aboutProjectGridItem}>
                <div id="video-player" tabIndex={-1}>
                  <VideoPlayer videoUrl={props.links.videoEmbedUrl} />
                </div>
              </div>
            )
          }
          content={<Description description={props.project.description} />}
          sidebar={
            <PublicFaceInfo
              publicFacePictureUrl={props.project.publicFace.profilePicture.url}
              publicFaceDisplayName={props.project.publicFace.displayName}
              carrierName={props.project.carrier?.name || '?'}
              newMessageUrl={props.links.newMessageUrl}
            />
          }
        />
      </Section>

      <Section spacing="large">
        <NeedList
          apiUrl={props.links.needsApiUrl}
          completedApiUrl={props.links.completedNeedsApiUrl}
          projectApiUrl={props.links.projectApiUrl}
        />
      </Section>

      <Section spacing="large" background="gentle" backgroundMobileOnly={true}>
        <Engagement
          allowFundraisingEvents={props.project.allowFundraisingEvents}
          subscribeCta={
            <SubscribeCta projectTitle={props.project.title || ''} url={props.links.newsletterSubscriptionUrl} />
          }
          createFundraisingEventCta={
            <CreateFundraisingEventCta
              ctaUrl={props.links.createFundraisingEventUrl}
              readmoreUrl={props.links.fundraisingEventLandingpageUrl}
            />
          }
        />
      </Section>

      <Section spacing="large" id="ppp-news-list">
        <NewsTeaserList blogsApiUrl={props.links.latestBlogsApiUrl} newsListUrl={props.links.newsListUrl} />
      </Section>

      <Section spacing="large" background="prominent" fullWidth={true}>
        <SharingContainer shareUrl={props.links.projectShareUrl} />
      </Section>

      {props.project.clientBannerList.length > 0 && (
        <Section spacing="large">
          <ClientBannerList clientBannerList={props.project.clientBannerList} />
        </Section>
      )}

      <Section spacing="large" background="foggy" fullWidth={true}>
        <About />
      </Section>
    </>
  )
}
